import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { pipe, subscribe } from "wonka";
import { getQuestConfigFromQuestId } from "../../components/quests/quests.util";
import { QuestNotification } from "../../components/quests/QuestSnackbar";
import { reclaim } from "../../reclaim-api";
import { UserQuests } from "../../reclaim-api/Users";
import { dtoToQuests } from "../../reclaim-api/Users.mutators";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useNotifications } from "../useNotifications";
import { useQuestsActions } from "./useQuests";

const questsAtom = atom<UserQuests | undefined>(undefined);

questsAtom.onMount = (setQuestsAtom) => {
  const { unsubscribe } = pipe(
    reclaim.users.fetchAndWatchQuests$$(),
    subscribe((quests: UserQuests | undefined) => {
      setQuestsAtom(quests);
    })
  );

  return () => unsubscribe();
};

export const useQuestsNotifications = () => {
  const { updateQuests } = useQuestsActions();
  const { sendNotification } = useNotifications();

  const [quests] = useAtom(questsAtom);
  const [currentQuests, setCurrentQuests] = useState<UserQuests | undefined>(undefined);

  const handleNewQuests = useCallbackSafeRef((quests: UserQuests) => {
    if (!currentQuests && !!quests) {
      setCurrentQuests(quests);
      updateQuests(dtoToQuests(quests));
    } else {
      const newItems = quests.completedQuests.filter((q) => !currentQuests?.completedQuests.some((cq) => cq === q));
      if (newItems.length) {
        const quest = getQuestConfigFromQuestId(newItems[0]);

        if (quest) {
          const closeHandler = sendNotification(<QuestNotification quest={quest} onClose={() => closeHandler()} />, {
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
            autoHideDuration: 6000,
          });
        }

        setCurrentQuests(quests);
        updateQuests(dtoToQuests(quests));
      }
    }
  });

  useEffect(() => {
    if (quests) handleNewQuests(quests);
  }, [quests, handleNewQuests]);
};
